import React from 'react';
import { Link } from 'react-router-dom';

export default (props) => {
	return (
		<div id="container">
			<div className="row">
				<div className="col s10 offset-s1 l8 offset-l2">
					<img width="203px" className="mbn3 mln4 mt4" src="/404.png" alt="page not found" />
				</div>
				<div className="col s10 offset-s1 l8 offset-l2">
					<h3 className="red-text darken-1 mt0 mb0">
						<strong>404 Error</strong>
					</h3>
					<h1 className="mt0">Sorry, we can’t seem to find what you’re looking for.</h1>
					<p className="flow-text">You've landed on a URL that doesn't seem to exist on our website.</p>
					<Link to="/">{'<-'} Go Back to Homepage</Link>
				</div>
			</div>
		</div>
	);
};
