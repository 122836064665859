import axios from 'axios';
import { API_URL } from '../utils/config';
import history from '../history';
import isEmpty from 'is-empty';

import {
	GET_ERRORS,
	VERIFY_SHORTID,
	GET_NEXT_QUESTION,
	END_ASSESSMENT,
	UPDATE_FINAL_ASSESSMENT_RESULTS,
	GET_FINAL_ASSESSMENT_RESULT,
	GET_ALL_QUESTIONS
} from './types';

const getCandidate = shortId => async dispatch => {
	await axios
		.post(`${API_URL}/api/assessment/`, { shortId })
		.then(res => {
			dispatch({ type: VERIFY_SHORTID, payload: res.data });
		}) // based on response received do the needful
		.catch(err => {
			dispatch({ type: GET_ERRORS, payload: { ...err.response.data, status: err.response.status } });
		});
};

// Get Next question from API based on shortId
const getQuestion = () => async (dispatch, getState) => {
	const { candidate } = getState().assessment;
	// If still no candidate returned then expired shortId, redirect to error page and display error
	if (isEmpty(candidate)) {
		dispatch({ type: GET_ERRORS, payload: { code: 99999, message: 'Candidate Assessment Is Unavailable' } });
		history.push('/');
	} else {
		// retrieve question using candidate
		await axios
			.post(`${API_URL}/api/assessment/start`, { candidate: candidate._id })
			.then(res => {
				if (!res.data.lastQuestion) {
					dispatch({ type: GET_NEXT_QUESTION, payload: res.data });
					dispatch({ type: GET_ERRORS, payload: {} });
				} else {
					dispatch(finishAssessment());
					history.push('/assessment/finish');
				}
			}) // based on response received do the needful
			.catch(err =>
				dispatch({ type: GET_ERRORS, payload: { ...err.response.data, status: err.response.status } })
			);
	}
};

const updateCandidate = post => async (dispatch, getState) => {
	const { candidate } = getState().assessment;
	const body = { ...candidate, status: 'started', ...post };
	await axios
		.post(`${API_URL}/api/assessment/update`, body)
		.then(res => dispatch({ type: VERIFY_SHORTID, payload: res.data }))
		.catch(err => dispatch({ type: GET_ERRORS, payload: { ...err.response.data, status: err.response.status } }));
};

const uploadProfilePicture = (file, old_picture) => async (dispatch, getState) => {
	const { candidate } = getState().assessment;
	// Create form data to send to the backend
	const data = new FormData();
	data.append('picture', file);
	data.append('shortId', candidate.shortId);
	data.append('old_picture', old_picture);
	const result = await axios
		.post(`${API_URL}/api/assessment/updateProfilePicture`, data)
		.then(res => {
			dispatch({ type: GET_ERRORS, payload: {} });
			return res;
		})
		.catch(err => dispatch({ type: GET_ERRORS, payload: { ...err.response.data, status: err.response.status } }));
	return !isEmpty(result.data) ? result.data[0] : null;
};

const recordAnswer = answer => async (dispatch, getState) => {
	const { question, candidate } = getState().assessment;
	const body = {
		q_type: question.type,
		q_id: question._id,
		q_title: question.title,
		a_value: answer.value,
		a_timetaken: answer.timetaken,
		c_id: candidate._id
	};
	await axios
		.post(`${API_URL}/api/assessment/record`, body)
		.then(res => res) // based on response received do the needful
		.catch(err => dispatch({ type: GET_ERRORS, payload: { ...err.response.data, status: err.response.status } }));
	// ! Add an action creator that signifies end of test and instructs the server to compute answer results as well as redirect user to end of test page with instructions on what to do next.
	dispatch(getQuestion());
};

// User loading
const finishAssessment = () => {
	return {
		type: END_ASSESSMENT
	};
};

const updateFinalAssessmentResults = () => async dispatch => {
	await axios
		.get(`${API_URL}/api/dashboard/responses/results/5e4aa99dc95d7d2fec1b6b61`)
		.then(res => {
			dispatch({ type: UPDATE_FINAL_ASSESSMENT_RESULTS, payload: res.data });
		}) // based on response received do the needful
		.catch(err => {
			dispatch({ type: GET_ERRORS, payload: { ...err.response.data, status: err.response.status } });
		});
};

const getFinalAssessmentResult = id => async (dispatch, getState) => {
	await axios
		.post(`${API_URL}/api/dashboard/responses`, { candidateId: id })
		.then(res => {
			dispatch({ type: GET_FINAL_ASSESSMENT_RESULT, payload: res.data });
		})
		.catch(err => {
			dispatch({ type: GET_ERRORS, payload: { ...err.response.data, status: err.response.status } });
		});
};

const getAllAssessmentQuestions = () => async dispatch => {
    await axios
		.post(`${API_URL}/api/dashboard/questions`, { id: '5e4aa99dc95d7d2fec1b6b61' })
		.then(res => {
			dispatch({ type: GET_ALL_QUESTIONS, payload: {id: '5e4aa99dc95d7d2fec1b6b61', questions: res.data} });
		})
		.catch(err => {
			dispatch({ type: GET_ERRORS, payload: { ...err.response.data, status: err.response.status } });
		});
}

export {
	getCandidate,
	getQuestion,
	recordAnswer,
	updateCandidate,
    uploadProfilePicture,
    getAllAssessmentQuestions,
	updateFinalAssessmentResults,
	getFinalAssessmentResult
};
