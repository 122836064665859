import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { getQuestion, getCandidate, recordAnswer } from '../../actions/assessmentActions';
import history from '../../history';
import isEmpty from 'is-empty';
import M from 'materialize-css';
import Loader from '../layout/Loader';
// Import form components
const Text = lazy(() => import('../formElements/text'));
const TextFill = lazy(() => import('../formElements/textFill'));
const TextArea = lazy(() => import('../formElements/textArea'));
const TextAreaFill = lazy(() => import('../formElements/textAreaFill'));
const TextAreaPre = lazy(() => import('../formElements/textAreaPre'));
const CheckBox = lazy(() => import('../formElements/checkBox'));
const RadioText = lazy(() => import('../formElements/radioText'));
const Radio = lazy(() => import('../formElements/radio'));

class Test extends Component {
	state = {
		question: {},
		answer: '',
		errors: {},
		submit: false,
		clicked: false,
		scroll: 0,
		secondary: '',
		windowHeight: ''
	};

	questionDiv = React.createRef();

	componentDidMount = async () => {
		!this.props.assessment.candidate.shortId && (await this.props.getCandidate(this.props.match.params.shortId));
		if (!this.props.assessment.candidate.updated) {
			history.push(`/assessment/start/${this.props.match.params.shortId}`);
		} else {
			await this.props.getQuestion();
			this.setScroll();
			window.addEventListener('resize', this.setScroll);
		}
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (this.state.question._id !== this.props.assessment.question._id) {
			this.setState({
				answer: ['long_pre', 'text_pre'].includes(this.props.assessment.question.type)
					? this.props.assessment.question.edit_area.text.split('\\n').join('\n\n')
					: '',
				question: this.props.assessment.question,
				errors: {},
				submit: false,
				clicked: false
			});
			if (document.querySelector('.materialboxed')) {
				const options = {
					onCloseEnd: () => {
						this.questionDiv.current.style.overflowY = 'scroll';
					}
				};
				M.Materialbox.init(document.querySelector('.materialboxed'), options);
			}
		}
	};

	setScroll = () => {
		this.setState({
			windowHeight: window.innerHeight * 0.65 + 'px',
			scroll: 'scroll'
		});
	};

	static getDerivedStateFromProps = nextProps => {
		if (nextProps.errors) {
			return { errors: nextProps.errors };
		}
		return null;
	};

	validate = flag => {
		this.setState({ submit: flag });
	};

	setSecondary = item => {
		let answer = this.state.answer;
		answer[1] = item;
		this.setState({ answer });
	};

	renderLoader = () => {
		return (
			<div className="preloader-wrapper big active">
				<div className="spinner-layer spinner-only darken-1">
					<div className="circle-clipper left">
						<div className="circle"></div>
					</div>
					<div className="gap-patch">
						<div className="circle"></div>
					</div>
					<div className="circle-clipper right">
						<div className="circle"></div>
					</div>
				</div>
			</div>
			// <div className="row center-align mt1 mbn1">
			// 	<div className="progress lime darken-1">
			// 		<div className="indeterminate lime accent-1"></div>
			// 	</div>
			// </div>
		);
	};

	onChange = e => {
		switch (e.target.type) {
			case 'text':
				this.setState({ [e.target.name]: e.target.value });
				break;
			case 'textarea':
				this.setState({ [e.target.name]: e.target.value });
				break;
			case 'checkbox':
				this.setState({
					[e.target.name]: e.target.checked
						? [...this.state[e.target.name], e.target.value]
						: this.state[e.target.name].filter(item => item !== e.target.value),
					submit: !e.target.checked && this.state[e.target.name].length === 1 ? false : true
				});
				break;
			case 'radio':
				if (this.props.assessment.question.type === 'radio_text') {
					let answer = [e.target.value];
					this.setState({ [e.target.name]: answer, submit: true });
				} else {
					this.setState({ [e.target.name]: e.target.value, submit: true });
				}
				break;
			default:
				break;
		}
	};

	onSubmit = e => {
		e.preventDefault();
		if (this.state.submit) {
			const submit = window.confirm(
				'IMPORTANT: You cannot come back to change your answer later!\n\nHit OK to submit and move ahead.\nOr Cancel to stay on the current question.'
			);
			if (submit) {
				this.setState({ clicked: true });
				const answer = {
					value:
						this.state.answer.indexOf('\n\n') === -1
							? this.state.answer
							: this.state.answer.split('\n\n').join('\\n'),
					timetaken: 120
				};
				// !Record the answer with the required details to populate the answer collection
				this.validate(false);
				this.props.recordAnswer(answer);
			}
		}
	};

	prettyTime = time => {
		var mins = ~~((time % 3600) / 60);
		var secs = ~~time % 60;

		// Output like "1:01" or "4:03:59" or "123:03:59"
		var ret = '';

		ret += '' + mins + 'min';
		ret += secs ? ' ' + (secs < 10 ? '0' : '') + secs + 'sec' : '';
		return ret;
	};

	buildQuestion = () => {
		const question = this.props.assessment.question;
		let html = '';
		if (!isEmpty(question.description)) {
			html += question.description;
			html += !isEmpty(question.answer_title) ? '<div class="description">' + question.title + '</div>' : '';
		} else {
			html += '<h6 class="description">' + question.title + '</h6>';
		}
		return (
			question.time && (
				<div className="mi0 pi0">
					<div className="mb2 red-text">
						<strong>Total time alloted: {this.prettyTime(question.time)}</strong>
					</div>
					<div
						ref={this.questionDiv}
						style={{ overflowY: this.state.scroll, maxHeight: this.state.windowHeight }}
						dangerouslySetInnerHTML={{ __html: html }}
						className="mi0 pi0"
					/>
				</div>
			)
		);
	};

	buildAnswer = () => {
		const { errors } = this.state;
		const {
			assessment: { question }
		} = this.props;
		let html = '';
		switch (question.type) {
			case 'text_empty':
				html = (
					<Text
						onChange={this.onChange}
						errors={errors.answer ? errors.answer : {}}
						settings={question.settings}
						edit_area={question.edit_area}
						answer={this.state.answer}
						submit={this.state.submit}
						validate={this.validate}
					/>
				);
				break;
			case 'text_fill':
				html = (
					<TextFill
						onChange={this.onChange}
						error={errors.answer ? errors.answer : {}}
						settings={question.settings}
						edit_area={question.edit_area}
						answer={this.state.answer}
						submit={this.state.submit}
						validate={this.validate}
					/>
				);
				break;
			case 'long_empty':
				html = (
					<TextArea
						onChange={this.onChange}
						error={errors.answer ? errors.answer : {}}
						settings={question.settings}
						edit_area={question.edit_area}
						answer={this.state.answer}
						submit={this.state.submit}
						validate={this.validate}
					/>
				);
				break;
			case 'long_pre':
				html = (
					<TextAreaPre
						onChange={this.onChange}
						error={errors.answer ? errors.answer : {}}
						settings={question.settings}
						edit_area={question.edit_area}
						answer={this.state.answer}
						submit={this.state.submit}
						validate={this.validate}
					/>
				);
				break;
			case 'long_fill':
				html = (
					<TextAreaFill
						onChange={this.onChange}
						error={errors.answer ? errors.answer : {}}
						settings={question.settings}
						edit_area={question.edit_area}
						answer={this.state.answer}
						submit={this.state.submit}
						validate={this.validate}
					/>
				);
				break;
			case 'checkbox':
				html = (
					<CheckBox
						onChange={this.onChange}
						q_id={this.state.question._id}
						errors={errors.answer ? errors.answer : {}}
						settings={question.settings}
						edit_area={question.edit_area}
						answer={this.state.answer}
						submit={this.state.submit}
						validate={this.validate}
					/>
				);
				break;
			case 'radio_text':
				html = (
					<RadioText
						onChange={this.onChange}
						q_id={this.state.question._id}
						errors={errors.answer ? errors.answer : {}}
						settings={question.settings}
						edit_area={question.edit_area}
						answer={this.state.answer}
						submit={this.state.submit}
						setSecondary={this.setSecondary}
						validate={this.validate}
					/>
				);
				break;
			case 'radio':
				html = (
					<Radio
						onChange={this.onChange}
						q_id={this.state.question._id}
						errors={errors.answer ? errors.answer : {}}
						settings={question.settings}
						edit_area={question.edit_area}
						answer={this.state.answer}
						submit={this.state.submit}
						validate={this.validate}
					/>
				);
				break;
			default:
				break;
		}
		return (
			question.time && (
				<>
					<div
						className="question"
						dangerouslySetInnerHTML={{
							__html: question.answer_title
								? question.answer_title
								: question.description
								? question.title
								: ''
						}}
					></div>
					<form onSubmit={this.onSubmit} action="#">
						<Suspense fallback={this.renderLoader()}>
							{html}
							<div className="fixed-action-btn">
								{this.state.clicked ? (
									<Loader noMargin="1" message="Submitting..." />
								) : (
									<button
										className="btn btn-large waves-effect waves-light red darken-1"
										disabled={!this.state.submit && 'disabled'}
									>
										Submit
									</button>
								)}
							</div>
						</Suspense>
					</form>
				</>
			)
		);
	};

	render = () => {
		return (
			<div className="container">
				<div style={{ height: 'inherit' }} className="row pt0">
					<div style={{ flexDirection: 'column', flex: 1 }} className="mb1 s12 center-align valign-wrapper">
						<h3 className="mb0 mt1">Yellafin Candidate Assessment</h3>
						<span className="red-text">
							CAUTION: This test is best taken on a computer using latest Chrome or Firefox browsers.
						</span>
						<br /> All questions are loosely timed. Try to answer each question in the time allotted.
						<br />
						Some questions can have more than one right answer.
					</div>
					<div className="mb3 divider"></div>
					{!this.state.question.time && this.renderLoader()}
					<div style={{ fontSize: '1.2rem' }} className="col s12 m6 full-screen-parent valign-wrapper">
						<div className="px2 full-screen-child valign-wrapper">{this.buildQuestion()}</div>
					</div>
					<div style={{ fontSize: '1.2rem' }} className="col s12 m6 full-screen-parent valign-wrapper">
						<div className="px1 full-screen-child valign-wrapper">{this.buildAnswer()}</div>
					</div>
				</div>
			</div>
		);
	};
}

const mapStateToProps = state => ({
	assessment: state.assessment,
	errors: state.errors
});

export default connect(mapStateToProps, { getQuestion, getCandidate, recordAnswer })(Test);
