import React, { Component } from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css';
import { removeErrors } from '../../actions/errorActions';

class Finish extends Component {
	componentDidMount = async () => {
		if (this.props.errors.code) {
			M.toast({ html: `${this.props.errors.code}: ${this.props.errors.message}`, classes: 'red darken-2' });
			this.props.removeErrors();
		}
	};

	render = () => {
		return (
			<div style={{ height: '75vh' }} className="container">
				<div className="row center-align mt3">
					<div className="col s12">
						<h3>Yellafin Candidate Assessment</h3>
					</div>
				</div>
				<div className="row">
					<div className="col s12">
						<p className="flow-text grey-text text-darken-1 mb0">
							<strong>Congratulations {this.props.assessment.candidate.name || 'Full Name'}!</strong> You
							have successfully completed your assessment. We shall get back to you shortly over a call
							and let you know your results.
							<br />
							<br />
							<span className="green-text text-darken-2">
								All that's left is to sit back, relax and wait. :)
							</span>
						</p>
					</div>
					<div className="col s8 m6 offset-s2 offset-m3 center-align">
						<br />
					</div>
				</div>
			</div>
		);
	};
}

const mapStateToProps = state => ({
	assessment: state.assessment,
	errors: state.errors
});

export default connect(mapStateToProps, { removeErrors })(Finish);
