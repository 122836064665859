import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from '../utils/setAuthToken';
import { setCurrentUser, logoutUser } from '../actions/authActions';
import 'materialize-css/dist/css/materialize.min.css';
import M from "materialize-css";
import history from '../history';

// Import Redux and store
import { Provider } from 'react-redux';
import store from '../store';

// Import custom css
import './App.css';
import './spacing.css';


//Custom components
import Navbar from './layout/Navbar';
import Landing from './layout/Landing';
import Register from './auth/Register';
import Login from './auth/Login';
import Test from './assessment/test';
import Finish from './assessment/finish';
import CandidateStart from './assessment/start';
import PrivateRoute from './private-route/PrivateRoute';
import Dashboard from './dashboard/Dashboard';
import Responses from './dashboard/finalAssessment/Responses';
import ErrorPage from './layout/Error';

// Check for token to keep user logged in
if (localStorage.jwtToken) {
	// Set auth token header auth
	const token = localStorage.jwtToken;
	setAuthToken(token);
	// Decode token and get user info and exp
	const decoded = jwt_decode(token);
	// Set user and isAuthenticated
	store.dispatch(setCurrentUser(decoded));
	// Check for expired token
	const currentTime = Date.now() / 1000; // to get in milliseconds
	if (decoded.exp < currentTime) {
		// Logout user
		store.dispatch(logoutUser());
		// Redirect to login
		window.location.href = './login';
	}
}

class App extends React.Component {
    componentDidMount = () => {
        M.AutoInit();
    }
	render = () => {
		return (
			<Provider store={store}>
				<Router history={history}>
					<div className="App">
						<Navbar />
						<Switch>
							<Route exact path="/" component={Landing} />
							<Route exact path="/register" component={Register} />
							<Route exact path="/login" component={Login} />
							<Route exact path="/assessment/start/:shortId" component={CandidateStart} />
							<Route exact path="/assessment/test/:shortId" component={Test} />
							<Route exact path="/assessment/finish" component={Finish} />
							<PrivateRoute exact path="/dashboard" component={Dashboard} />
							<PrivateRoute exact path="/dashboard/finalAssessment/responses/" component={Responses} />
							<Route component={ErrorPage} />
						</Switch>
					</div>
				</Router>
			</Provider>
		);
	};
}

export default App;
