import { GENERATE_CANDIDATE_INVITE, GET_CANDIDATE_INVITES } from '../actions/types';

// Set initial State to an empty array
let initialState = {
    size: 5,
    items: []
};
    
export default (state = initialState, action) => {
	switch (action.type) {
		case GENERATE_CANDIDATE_INVITE:
            return {...state, items: [action.payload, ...state.items]};
        case GET_CANDIDATE_INVITES:
            return {...state, items: action.payload};
		default:
			return state;
	}
};
