import {combineReducers} from "redux";
import authReducer from "./authReducers";
import errorReducer from "./errorReducers";
import candidateReducer from "./candidateReducers";
import assessmentReducer from "./assessmentReducers";
import dashboardReducer from "./dashboard/";

export default combineReducers({
    auth: authReducer,
    invites: candidateReducer,
    assessment: assessmentReducer,
    dashboard: dashboardReducer,
    errors: errorReducer
});