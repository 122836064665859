import { VERIFY_SHORTID, GET_NEXT_QUESTION, END_ASSESSMENT } from '../actions/types';
import isEmpty from 'is-empty';

const initialState = {
	isVerified: false,
    candidate: {},
    question: {},
	complete: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case VERIFY_SHORTID:
			return {
				...state,
				isVerified: !isEmpty(action.payload),
				candidate: action.payload
            };
        case GET_NEXT_QUESTION:
            return {
                ...state,
                question: action.payload
            }
        case END_ASSESSMENT:
            return {
                ...state,
                complete: true
            }
		default:
			return state;
	}
};
