import React, { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import M from 'materialize-css';
import { logoutUser } from '../../actions/authActions';


// Import custom Components
import GenerateCandidateInvite from '../candidate/GenerateCandidateInvite';
const CandidateInvites = lazy(() => import('../candidate/CandidateInvites'));
const LatestAssessments = lazy(() => import('./finalAssessment/LatestAssessments'));
class Dashboard extends Component {
	state = {
        showInvites: false,
        showFinalAssessments: false
	};

	componentDidMount = () => {
		M.AutoInit();
	};

	show = key => {
		this.setState({ [key]: true });
	};

	renderLoader = () => {
		return (
			<div className="row center-align mt1 mbn1">
				<div className="progress lime darken-1">
					<div className="indeterminate lime accent-1"></div>
				</div>
			</div>
		);
	};

	onLogoutClick = e => {
		e.preventDefault();
		this.props.logoutUser();
	};

	render() {
		const { user } = this.props.auth;
		const { showInvites, showFinalAssessments } = this.state;
		return (
			<div className="container valign-wrapper">
				<div className="row">
					<div className="col s12 center-align">
						<h4>
							<b>Hey there,</b> {user.name.split(' ')[0]}
							<p className="flow-text grey-text text-darken-1">
								Welcome back to <span style={{ fontFamily: 'monospace' }}>Yellafin</span> portal 👏
							</p>
						</h4>
					</div>
					<div className="col s12 l6">
						<ul className="collapsible">
							<li>
								<h5 className="ml2">Candidate Invitations</h5>
							</li>
							<li>
								<div onClick={() => this.show('showInvites')} className="collapsible-header red-text">
									See all candidate invitations
								</div>
								<div className="collapsible-body">
									{showInvites && (
										<Suspense fallback={this.renderLoader()}>
											<CandidateInvites size="5" />
										</Suspense>
									)}
								</div>
							</li>
							<li>
								<div className="collapsible-header red-text">Invite a new candidate</div>
								<div className="pt2 collapsible-body">
									<GenerateCandidateInvite />
								</div>
							</li>
						</ul>
					</div>
					<div className="col s12 l6">
						<ul className="collapsible">
							<li>
								<h5 className="ml2">Final Assessments</h5>
							</li>
							<li>
								<div
									onClick={() => this.show('showFinalAssessments')}
									className="collapsible-header red-text"
								>
									See latest final assessments
								</div>
								<div className="collapsible-body">
									{showFinalAssessments && (
										<Suspense fallback={this.renderLoader()}>
											<LatestAssessments size="5" />
										</Suspense>
									)}
								</div>
							</li>
						</ul>
					</div>
					<div className="col s12 center-align">
						<button
							style={{
								width: '150px',
								borderRadius: '3px',
								letterSpacing: '1.5px'
							}}
							onClick={this.onLogoutClick}
							className="mt3 btn btn-large waves-effect waves-light hoverable red darken-1"
						>
							Logout
						</button>
					</div>
				</div>
			</div>
		);
	}
}
Dashboard.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
	auth: state.auth
});
export default connect(mapStateToProps, { logoutUser })(Dashboard);
