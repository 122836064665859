import React from 'react';
import { connect } from 'react-redux';
import { generateCandidateInvite } from '../../actions/candidateActions';
import classnames from 'classnames';
import isEmpty from 'is-empty';

class GenerateCandidateInvite extends React.Component {
	state = {
		name: '',
		email: '',
		phone: '',
		shortId: '',
		errors: {},
		submit: false
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (
			((isEmpty(prevState.shortId) && !isEmpty(this.props.shortId)) ||
				(!isEmpty(prevState.shortId) && prevState.shortId !== this.props.shortId)) &&
			prevState.submit &&
			isEmpty(this.state.errors)
		) {
			this.setState({ shortId: this.props.shortId, name: '', email: '', phone: '' });
		} else if (prevState.submit && !isEmpty(this.state.errors)) {
			this.setState({ submit: false, shortId: '' });
		}
	};
	static getDerivedStateFromProps = (nextProps, prevState) => {
		if (nextProps.errors) {
			return { errors: nextProps.errors };
		}
		if (nextProps.shortId) {
			return { shortId: nextProps.shortId };
		}
		return null;
	};

	onChange = e => {
		this.setState({ [e.target.id]: e.target.value });
	};

	onSubmit = e => {
		e.preventDefault();

		const newCandidate = {
			name: this.state.name,
			email: this.state.email,
			phone: this.state.phone
		};

		this.setState({ submit: true });
		this.props.generateCandidateInvite(newCandidate);
	};

	render = () => {
		const { shortId, errors } = this.state;
		return (
			<div className="container">
				<div className="row">
					<form noValidate onSubmit={this.onSubmit}>
						<div className="input-field">
							<input
								onChange={this.onChange}
								value={this.state.name}
								error={errors.name}
								id="name"
								type="text"
								className={classnames('', { invalid: errors.name })}
							/>
							<label htmlFor="name">Candidate Name</label>
							<span className="red-text">{errors.name}</span>
						</div>
						<div className="input-field">
							<input
								onChange={this.onChange}
								value={this.state.email}
								error={errors.email}
								id="email"
								type="email"
								className={classnames('', { invalid: errors.email })}
							/>
							<label htmlFor="email">Candidate Email</label>
							<span className="red-text">{errors.email}</span>
						</div>
						<div className="input-field">
							<input
								onChange={this.onChange}
								value={this.state.phone}
								error={errors.phone}
								id="phone"
								type="text"
								className={classnames('', { invalid: errors.phone })}
							/>
							<label htmlFor="phone">Candidate Phone</label>
							<span className="red-text">{errors.phone}</span>
						</div>
						<div className="col s12 center-align">
							<button
								style={{
									width: '170px',
									borderRadius: '3px',
									letterSpacing: '1px',
								}}
								type="submit"
								className="mt1 btn waves-effect waves-light hoverable black-text grey lighten-1"
							>
								Generate Invite
							</button>
						</div>
					</form>
				</div>
				{shortId !== '' && (
					<div className="row">
						<p>
							Test URL for Candidate is <br />
							<span style={{ fontWeight: '700' }}>https://yellafin.in/assessment/start/{shortId}</span>
						</p>
					</div>
				)}
			</div>
		);
	};
}

const mapStateToProps = state => ({
	errors: state.errors,
	shortId: !isEmpty(state.invites.items) ? state.invites.items[0]['shortId'] : ''
});

export default connect(mapStateToProps, { generateCandidateInvite })(GenerateCandidateInvite);
