import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import M from 'materialize-css';
import { removeErrors } from '../../actions/errorActions';
// Custom module imports
import UseToken from '../assessment/UseToken';

class Landing extends React.Component {
	componentDidMount = () => {
		if (this.props.isAuthenticated) {
			this.props.history.push('/dashboard');
		}
		if (this.props.errors.code) {
			M.toast({ html: `${this.props.errors.code}: ${this.props.errors.message}`, classes: 'red darken-2' });
			this.props.removeErrors();
		}
	};

	render = () => {
		return (
			<div style={{ height: '75vh' }} className="container">
				<div className="row center-align mt3 mb2">
					<div className="col s12">
						<h4>You are at the Assessment section for Yellafin Pvt Ltd</h4>
					</div>
					{/* <div className="col s6">
							<Link
								to="/register"
								style={{ width: '140px', borderRadius: '3px', letterSpacing: '1.5px' }}
								className="btn btn-large black-text waves-effect waves-light hoverable lime darken-1"
							>
								Register
							</Link>
						</div> */}
					<div className="col s12">
						<Link
							to="/login"
							style={{
								width: '140px',
								borderRadius: '3px',
								letterSpacing: '1.5px'
							}}
							className="mt2 btn waves-effect waves-light hoverable black-text grey lighten-1"
						>
							Log In
						</Link>
					</div>
				</div>
				<div className="row center-align">
					<div className="col s12 center-align">
						<p className="flow-text grey-text text-darken-1 mb0">
							OR if you have received a token then enter it below to take your assessment.
						</p>
					</div>
					<div className="col s8 m6 offset-s2 offset-m3 center-align">
						<UseToken />
						<br />
					</div>
				</div>
			</div>
		);
	};
}

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
	errors: state.errors
});

export default connect(mapStateToProps, { removeErrors })(Landing);
