import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import history from '../../history';

class UseToken extends Component {
	state = {
		token: '',
		errors: {}
	};

	onChange = e => {
		this.setState({ [e.target.id]: e.target.value });
	};

	onSubmit = async e => {
        e.preventDefault();
        history.push(`/assessment/start/${this.state.token}`);
	};

	render = () => {
		const { errors } = this.state;
		return (
			<div className="row pt0">
				<form noValidate onSubmit={this.onSubmit}>
					<div className="input-field col s9 m10 mb0">
						<input
							onChange={this.onChange}
							value={this.state.token}
							error={errors.token}
							id="token"
							type="text"
							className={classnames('', {
								invalid: errors.token
							})}
						/>
						<label htmlFor="token">Enter your assessment token</label>
					</div>
					<div className="input-field col s3 m2 mb0">
						<button
							type="submit"
							className="mt1 btn waves-effect waves-light hoverable black-text grey light-1"
						>
							<i className="material-icons">send</i>
						</button>
					</div>
					<div className="col s12 mx0 left-align">
						<span className="red-text">{errors.token}</span>
						<div style={{ fontSize: '0.7rem', lineHeight: '12px' }} className="grey-text text-darken-2">
							Ex: XYZ3243A - The last part of the assessment link shared with you by your recruiter or
							sent to your email.
						</div>
					</div>
				</form>
			</div>
		);
	};
}
const mapStateToProps = state => ({
    errors: state.errors,
});
export default connect(mapStateToProps)(UseToken);
