import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'is-empty';
import { getCandidate, updateCandidate, uploadProfilePicture } from '../../actions/assessmentActions';
import { removeErrors } from '../../actions/errorActions';
import classnames from 'classnames';
import history from '../../history';
import M from 'materialize-css';
// Import Loader
import Loader from '../layout/Loader';
class Start extends Component {
	state = {
		name: '',
		email: '',
		phone: '',
		shortId: '',
		dob: '',
		id_proof: '',
		old_picture: '',
		picture: '',
		upload: false,
		errors: {},
		submit: false
	};

	dobRef = React.createRef();

	componentDidMount = async () => {
		!this.props.candidate.shortId && (await this.props.getCandidate(this.props.match.params.shortId));
		if (!this.props.candidate.shortId) {
			history.push('/');
        } else if(this.props.candidate.updated) history.push(`/assessment/test/${this.props.match.params.shortId}`) 
        else {
            const { candidate } = this.props;
            this.setState({
				name: candidate.name,
				email: candidate.email,
				phone: candidate.phone,
				shortId: candidate.shortId
            });
            M.updateTextFields();
			const currYear = new Date().getFullYear();
			M.Datepicker.init(this.dobRef.current, {
				defaultDate: new Date(currYear - 5, 0, 1),
				// setDefaultDate: new Date(2000,01,31),
				maxDate: new Date(currYear - 5, 12, 31),
				yearRange: [1928, currYear - 5],
				format: 'dd/mm/yyyy',
				onClose: date => {
					this.setState({ dob: this.dobRef.current.value });
				}
            });
		}
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (this.props.candidate.updated) history.push(`/assessment/test/${this.props.match.params.shortId}`);
		if (this.props.errors.code) {
			M.toast({ html: `${this.props.errors.code}: ${this.props.errors.message}`, classes: 'red darken-2' });
			this.props.removeErrors();
        }
        if (this.props.errors.status && this.props.errors.status !== 400) {
			M.toast({ html: `${this.props.errors.status}: ${this.props.errors.message}`, classes: 'red darken-2' });
			this.props.removeErrors();
		}
	};

	static getDerivedStateFromProps = nextProps => {
		if (!isEmpty(nextProps.errors)) {
			return { errors: nextProps.errors, submit: false };
		}
		return null;
	};

	onChange = async e => {
		if (e.target.name === 'picture') {
			if (isEmpty(e.target.files[0])) this.setState({ errors: { picture: 'This is a required field!' } });
			else {
                if(e.target.files[0].size > 5100000) return this.setState({ errors: {picture: 'Use a free online image compression tool and reduce picture size below 5Mb or convert to jpg format and try again'} });
                this.setState({ upload: true });
                
				const uploaded = await this.props.uploadProfilePicture(e.target.files[0], this.state.old_picture);
				if (!isEmpty(uploaded)) {
					M.toast({
						html: '<i class="material-icons">file_upload</i> Image uploaded successfully!',
						classes: 'lime darken-1 black-text'
					});
					this.setState({ picture: uploaded[0], old_picture: uploaded[1], upload: false, errors: {} });
				} else {
					this.setState({ upload: false });
				}
			}
		} else {
			this.setState({ [e.target.name]: e.target.value });
		}
	};

	onSubmit = e => {
		e.preventDefault();
		if (isEmpty(this.state.picture))
			this.setState({ errors: { picture: 'Please upload an image as per instructions above' } });
		else {
			const candidate = {
				cn_name: this.state.name.trim(),
				cn_email: this.state.email.trim(),
				cn_phone: this.state.phone.trim(),
				dob: this.state.dob.trim(),
				sex: this.state.sex.trim(),
				id_proof: this.state.id_proof.trim(),
				picture: this.state.picture
			};

			this.setState({ submit: true });
			this.props.updateCandidate(candidate);
		}
	};

	render = () => {
		const { errors } = this.state;

		return (
			<div className="container">
				<div className="mt2 row">
					<div className="col s10 offset-s1 l8 offset-l2">
						<div className="col s12" style={{ paddingLeft: '11.250px' }}>
							<h4>
								<b>Before</b> you start your assessment...
							</h4>
						</div>
						<form onSubmit={this.onSubmit}>
							<div className="col s12">
								<h6>
									Verify that your <strong>name, email and contact number</strong> are as given in
									your CV
								</h6>
								<p>
									<i>or update incorrect information before proceeding.</i>
								</p>
							</div>
							<div className="input-field col s12">
								<input
									onChange={this.onChange}
									value={this.state.name}
									error={errors.name}
									id="name"
									name="name"
									type="text"
									className={classnames('', { invalid: errors.name })}
									required="required"
								/>
								<label className="active" htmlFor="name">
									Name
								</label>
								<span className="red-text">{errors.name}</span>
							</div>
							<div className="input-field col s12 m6">
								<input
									ref={this.dobRef}
									onChange={this.onChange}
									value={this.state.dob}
									error={errors.dob}
									id="dob"
									name="dob"
									type="text"
									className={classnames('no-autoinit datepicker', { invalid: errors.dob })}
									required="required"
								/>
								<label className="active" htmlFor="dob">
									Date of Birth
								</label>
								<span className="red-text">{errors.dob}</span>
							</div>
							<div className="input-field col s12 m6 mt1 mb0">
								<span style={{ color: '#9e9e9e' }}>Sex</span>
								<p className="answer">
									<label>
										<input
											onChange={this.onChange}
											type="radio"
											name="sex"
											value="female"
											checked={this.state.sex === 'female'}
											required="required"
										/>
										<span>Female </span>
									</label>
									<label className="ml2">
										<input
											onChange={this.onChange}
											type="radio"
											name="sex"
											value="male"
											checked={this.state.sex === 'male'}
											required="required"
										/>
										<span>Male</span>
									</label>
								</p>
								<span className="red-text">{errors.sex}</span>
							</div>
							<div className="input-field col s12">
								<input
									onChange={this.onChange}
									value={this.state.email}
									error={errors.email}
									id="email"
									name="email"
									type="email"
									className={classnames('', { invalid: errors.email })}
									required="required"
								/>
								<label className="active" htmlFor="email">
									Email
								</label>
								<span className="red-text">{errors.email}</span>
							</div>
							<div className="input-field col s12">
								<input
									onChange={this.onChange}
									value={this.state.phone}
									error={errors.phone}
									id="phone"
									name="phone"
									type="tel"
									className={classnames('', { invalid: errors.phone })}
									required="required"
								/>
								<label className="active" htmlFor="phone">
									Phone no.
								</label>
								<span className="red-text">{errors.phone}</span>
							</div>
							<div className="col s12">
								<h6>
									Provide any <strong>government approved identification number</strong>. You must
									bring the same to your physical interview, if selected.
								</h6>
								<p>
									<i>Ex: PAN Card, Aadhar Card, Passport...</i>
								</p>
							</div>
							<div className="input-field col s12">
								<input
									onChange={this.onChange}
									value={this.state.id_proof}
									error={errors.id_proof}
									id="id_proof"
									name="id_proof"
									type="text"
									className={classnames('', { invalid: errors.id_proof })}
									required="required"
								/>
								<label className="active" htmlFor="id_proof">
									Enter your ID proof number
								</label>
								<span className="red-text">{errors.id_proof}</span>
							</div>
							<div className="col s12 m8">
								<h6>
									Upload a picture of you holding up a piece of paper having your{' '}
									<strong>name and today's date</strong> to confirm it really is you taking the
									assessment ;)
								</h6>
								<p>
									<i>See sample image to the side</i>
									<br />
									<i>
										Larger files take longer to upload. Preferrably, keep your file size below 5mb.
									</i>
								</p>
								<div className="file-field input-field">
									{this.state.upload ? (
										<Loader message="Uploading image, this may take a minute or two..." />
									) : (
										<div className="btn waves-effect waves-light hoverable black-text grey lighten-1">
											<span>File</span>
											<input
												onChange={this.onChange}
												error={errors.picture}
												id="picture"
												type="file"
												name="picture"
												accept="image/*"
												className={classnames('', { invalid: errors.picture })}
											/>
										</div>
									)}
									<div className="file-path-wrapper">
										<input className="file-path validate" type="text" />
									</div>
									<span className="red-text">{errors.picture}</span>
								</div>
							</div>
							<div className="col s12 m4">
								{!isEmpty(this.state.picture) ? (
									<img className="responsive-img" src={this.state.picture} alt="profile" />
								) : (
									<img
										src={
											this.state.preview
												? this.state.preview
												: this.state.sex === 'male'
												? '/mugshot_male.jpg'
												: '/mugshot_female.jpg'
										}
										className="responsive-img"
										alt="Sample With Name And Today's Date"
									/>
								)}
							</div>
							<input type="checkbox" name="check" />
							<div className="col s12" style={{ paddingLeft: '11.250px' }}>
								{this.state.submit ? (
									<Loader />
								) : (
									<button
										style={{
											width: 'auto',
											borderRadius: '3px',
											letterSpacing: '1.5px',
										}}
										type="submit"
										className="mt3 btn waves-effect waves-light hoverable red darken-1"
									>
										Let's Begin!
									</button>
								)}
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	};
}

const mapStateToProps = state => ({
	candidate: state.assessment.candidate,
	errors: state.errors
});

export default connect(mapStateToProps, { getCandidate, updateCandidate, removeErrors, uploadProfilePicture })(Start);
