import { GET_ALL_QUESTIONS, GET_FINAL_ASSESSMENT_RESULT } from '../../actions/types';
import isEmpty from 'is-empty';

const initialState = {
    id: '',
    questions: {},
    answers: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_QUESTIONS:
			return {
				...state,
				id: !isEmpty(action.payload.id) ? action.payload.id : '',
				questions: !isEmpty(action.payload.questions) ? action.payload.questions : []
			};
		case GET_FINAL_ASSESSMENT_RESULT:
			return {
				...state,
				answers: action.payload
			};
		default:
			return state;
	}
};
