import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { API_URL } from '../utils/config';
import history from '../history';

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from './types';

// Register User
export const registerUser = userData => dispatch => {
	axios
		.post(`${API_URL}/api/users/register`, userData)
		.then(res => {
            dispatch({type: GET_ERRORS, payload: {}});
			history.push('/login');
		}) // redirect to login on successful register
		.catch(err => dispatch({ type: GET_ERRORS, payload: { ...err.response.data, status: err.response.status } }));
};

// Login - get user token
export const loginUser = userData => dispatch => {
	axios
		.post(`${API_URL}/api/users/login`, userData)
		.then(res => {
			// Save to locaStorage

			// Set token to localStorage
			const { token } = res.data;
			localStorage.setItem('jwtToken', token);
			// Set token to Auth header
			setAuthToken(token);
			// Decode token to get user data
			const decoded = jwt_decode(token);
			// Set current user
            dispatch(setCurrentUser(decoded));
            dispatch({ type: GET_ERRORS, payload: {} });
		})
		.catch(err => dispatch({ type: GET_ERRORS, payload: { ...err.response.data, status: err.response.status } }));
};

// Set logged in user
export const setCurrentUser = decoded => {
	return {
		type: SET_CURRENT_USER,
		payload: decoded
	};
};

// User loading
export const setUserLoading = () => {
	return {
		type: USER_LOADING
	};
};

// Log user out
export const logoutUser = () => dispatch => {
	// Remove token from local storage
	localStorage.removeItem('jwtToken');
	// Remove auth header for future requests
	setAuthToken(false);
	// Set current user to empty object () which will set isAuthenticated to false
	dispatch(setCurrentUser({}));
};
