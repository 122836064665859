import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import isEmpty from 'is-empty';
import M from 'materialize-css';
import {
	getFinalAssessmentResult,
	updateFinalAssessmentResults,
	getAllAssessmentQuestions
} from '../../../actions/assessmentActions';
import { removeErrors } from '../../../actions/errorActions';
import history from '../../../history';
import Loader from '../../layout/Loader';

class Responses extends Component {
	state = {
		currentCandidate: !isEmpty(this.props.location.state) ? this.props.location.state : '',
		windowHeight: '',
		scroll: 0
	};

	componentDidMount = async () => {
		if (isEmpty(this.props.candidates)) await this.props.updateFinalAssessmentResults();
		if (isEmpty(this.props.questions)) await this.props.getAllAssessmentQuestions();
		await this.props.getFinalAssessmentResult(this.state.currentCandidate._id);
		this.setScroll();
		window.addEventListener('resize', this.setScroll);
		M.AutoInit();
	};

	componentDidUpdate = async (prevProps, prevState) => {
		if (!this.state.currentCandidate) history.push('/');
		if (this.state.currentCandidate._id !== prevState.currentCandidate._id) {
			await this.props.getFinalAssessmentResult(this.state.currentCandidate._id);
		}
	};

	renderLoader = () => {
		return (
			<div className="preloader-wrapper big active">
				<div className="spinner-layer spinner-only darken-1">
					<div className="circle-clipper left">
						<div className="circle"></div>
					</div>
					<div className="gap-patch">
						<div className="circle"></div>
					</div>
					<div className="circle-clipper right">
						<div className="circle"></div>
					</div>
				</div>
			</div>
			// <div className="row center-align mt1 mbn1">
			// 	<div className="progress lime darken-1">
			// 		<div className="indeterminate lime accent-1"></div>
			// 	</div>
			// </div>
		);
	};

	prettyTime = time => {
		var mins = ~~((time % 3600) / 60);
		var secs = ~~time % 60;

		// Output like "1:01" or "4:03:59" or "123:03:59"
		var ret = '';

		ret += '' + mins + 'min';
		ret += secs ? ' ' + (secs < 10 ? '0' : '') + secs + 'sec' : '';
		return ret;
	};

	setScroll = () => {
		if (window.innerWidth > 600) {
			this.setState({
				windowHeight: window.innerHeight * 0.8 + 'px',
				scroll: 'scroll'
			});
		}
	};

	renderDisplay = () => {
		const { questions, answers } = this.props;
		const components = questions.map((question, key) => {
			// Prepare the starting of the question
			let question_html = '';
			if (!isEmpty(question.description)) {
				question_html += question.description;
				question_html += !isEmpty(question.answer_title)
					? '<div class="description">' + question.title + '</div>'
					: '';
			} else {
				question_html += '<h6 class="description">' + question.title + '</h6>';
			}

			// Prepare the answer to display for the question
			const answer = answers.filter(item => item.question.q_id === question._id);
            let answer_html = '';
            if(answer.length) {
                if (typeof answer[0].value === 'string') {
					const bits = answer[0].value.split('\\n');
					answer_html = (
						<div className="answer teal-text">
							{bits.map((bit) => (
								<>
									<p className="pt0 mt0">{bit}</p>
								</>
							))}
						</div>
					);
				} else {
					answer_html = (
						<div className="answer teal-text">
							{answer[0].value.map((bit, key) => (
								<>
									<p className="mbi1 mti1">
										<i className="tiny material-icons mr1">fiber_manual_record</i>
										{bit}
									</p>
								</>
							))}
						</div>
					);
				}
            }
			return (
				<>
					<div className="row">
						<div style={{ fontSize: '1.2rem' }} className="col s12 m6">
							<h4>Question {key + 1}</h4>
							<div className="mb2 red-text">
								<strong>Total time alloted: {this.prettyTime(question.time)}</strong>
							</div>
							<div
								ref={this.questionDiv}
								dangerouslySetInnerHTML={{ __html: question_html }}
								className="mi0 pi0"
							/>
						</div>
						<div style={{ fontSize: '1.2rem' }} className="col s12 m6">
							<div className="px1">
								<div
									className="question"
									dangerouslySetInnerHTML={{
										__html: question.answer_title
											? question.answer_title
											: question.description
											? question.title
											: ''
									}}
								></div>
								{!isEmpty(question.edit_area.options) &&
									question.edit_area.options.map(bit => (
										<p className="mbi1 mti1">
											<strong>
												<i className="tiny material-icons mr1">fiber_manual_record</i>
												{bit}
											</strong>
										</p>
									))}
								<h4>Answer {key + 1}</h4>
								{answer_html}
							</div>
						</div>
					</div>
					<div className="mb3 divider"></div>
				</>
			);
		});
		return components;
	};

	renderCandidate = () => {
		let prev = '',
			next = '',
			current_key;
		let candidates = this.props.candidates;
		candidates.map((item, index) => {
			if (item._id === this.state.currentCandidate._id) {
				current_key = index;
				return;
			}
		});
		if (current_key === 0) {
			next = 1;
		} else if (current_key === this.props.candidates.length - 1) {
			prev = this.props.candidates.length - 2;
		} else {
			next = current_key + 1;
			prev = current_key - 1;
		}
		return (
			<>
				<img
					className="responsive-img"
					src={this.state.currentCandidate.picture}
					alt="profile"
				/>
				<p style={{ fontSize: '1.2rem' }} className="flow-text">
					<strong>Name: </strong>
					<span>{this.state.currentCandidate.name}</span>
					<br />
					<strong>Sex: </strong>
					<span>{this.state.currentCandidate.sex}</span>
					<br />
					<strong>Email: </strong>
					<span>{this.state.currentCandidate.email}</span>
					<br />
					<strong>Phone: </strong>
					<span>{this.state.currentCandidate.phone}</span>
					<br />
					<strong>DOB: </strong>
					<span>{this.state.currentCandidate.dob}</span>
					<br />
					<strong>ID Proof: </strong>
					<span>{this.state.currentCandidate.id_proof}</span>
					<br />
					<strong>Email: </strong>
					<span>{this.state.currentCandidate.email}</span>
				</p>
				{prev !== '' && (
					<Link
						onClick={() => {
							this.setState({ currentCandidate: this.props.candidates[prev] });
						}}
						to={{
							pathname: `/dashboard/finalAssessment/responses/`,
							state: this.props.candidates[prev]
						}}
						className="flow-text left"
					>
						<i className="tiny material-icons mr1">skip_previous</i>
						{'Prev'}
					</Link>
				)}
				{next !== '' && (
					<Link
						onClick={() => {
							this.setState({ currentCandidate: this.props.candidates[next] });
						}}
						to={{
							pathname: `/dashboard/finalAssessment/responses/`,
							state: this.props.candidates[next]
						}}
						className="flow-text right"
					>
						{'Next'}
						<i class="tiny material-icons ml1">skip_next</i>
					</Link>
				)}
			</>
		);
	};

	render = () => {
		return (
			<div style={{ overflowY: 'hidden' }} className="row mx2">
				<div className="col s12 mb1">
					<div style={{ flexDirection: 'column', flex: 1 }} className="mb1 s12 center-align valign-wrapper">
						<h3 className="mb0">Yellafin Candidate Answers</h3>
					</div>
				</div>
				{(isEmpty(this.props.questions) || isEmpty(this.props.answers)) && (
					<div className="col s12 center-align mti4">
						<Loader />
					</div>
				)}
				{!isEmpty(this.props.questions) && !isEmpty(this.props.answers) && (
					<>
						<div className="col m4 l2 fixed">
							{!isEmpty(this.state.currentCandidate) && this.renderCandidate()}
						</div>
						<div
							style={{
								overflowX: 'hidden',
								overflowY: this.state.scroll,
								maxHeight: this.state.windowHeight
							}}
							className="col s12 m8 l9 offset-l1"
						>
							<div style={{ height: 'inherit' }} className="row pt0">
								{!isEmpty(this.props.questions) && !isEmpty(this.props.answers) && this.renderDisplay()}
							</div>
						</div>
					</>
				)}
			</div>
		);
	};
}

const mapStateToProps = state => ({
	questions: state.dashboard.assessment.questions,
	candidates: state.dashboard.candidates,
	answers: state.dashboard.assessment.answers,
	errors: state.errors
});

export default connect(mapStateToProps, {
	getFinalAssessmentResult,
	updateFinalAssessmentResults,
	getAllAssessmentQuestions,
	removeErrors
})(Responses);
