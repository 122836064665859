export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GENERATE_CANDIDATE_INVITE = "GENERATE_CANDIDATE_INVITE";
export const GET_CANDIDATE_INVITES = "GET_CANDIDATE_INVITES";
export const QUESTION_LOADING = "QUESTION_LOADING";
export const GET_NEXT_QUESTION = "GET_NEXT_QUESTION";
export const VERIFY_SHORTID = "VERIFY_SHORTID";
export const RECORD_ANSWER = "RECORD_ANSWER";
export const END_ASSESSMENT = "END_ASSESSMENT";
export const UPDATE_FINAL_ASSESSMENT_RESULTS = 'UPDATE_FINAL_ASSESSMENT_RESULTS';
export const GET_FINAL_ASSESSMENT_RESULT = 'GET_FINAL_ASSESSMENT_RESULT';
export const GET_ALL_QUESTIONS = 'GET_ALL_QUESTIONS';