import axios from 'axios';
import { GET_ERRORS, GENERATE_CANDIDATE_INVITE, GET_CANDIDATE_INVITES } from './types';
import { API_URL } from '../utils/config';
import isEmpty from 'is-empty';

export const generateCandidateInvite = candidateData => dispatch => {
	axios
		.post(`${API_URL}/api/candidate`, candidateData)
		.then(res => {
            dispatch({type: GET_ERRORS, payload: {}});
            dispatch({ type: GENERATE_CANDIDATE_INVITE, payload: res.data });
		})
		.catch(err => dispatch({ type: GET_ERRORS, payload: { ...err.response.data, status: err.response.status } }));
};

export const getCandidateInvites = (size) => (dispatch, getState) => {
    const q = isEmpty(size) ? getState().invites.size : size;
	axios
		.get(`${API_URL}/api/candidate${!isEmpty(q) ? '?q=' + q : ''}`)
		.then(res => {
			dispatch({ type: GET_CANDIDATE_INVITES, payload: res.data });
		})
		.catch(err => dispatch({ type: GET_ERRORS, payload: { ...err.response.data, status: err.response.status } }));
};
