import { UPDATE_FINAL_ASSESSMENT_RESULTS } from '../../actions/types';

// Set initial State to an empty array
let initialState = '';

export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_FINAL_ASSESSMENT_RESULTS:
			return action.payload;
		default:
			return state;
	}
};
