import React from 'react';
import { Link } from 'react-router-dom';

class Navbar extends React.Component {
	render = () => {
		return (
			<div style={{marginBottom: "10px", height: "100px"}} className="navbar-fixed">
					<nav style={{height: "inherit"}}>
						<div style={{height: "inherit"}} className="nav-wrapper white logo-container">
							<Link to="/" style={{ fontFamily: 'monospace' }} className="brand-logo center">
								<img style={{marginTop: "25px"}} width="200" height="auto" src="/yellafin_logo.png" className="logo" alt="" />
							</Link>
						</div>
					</nav>
			</div>
		);
	};
}

export default Navbar;
