import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registerUser } from '../../actions/authActions';
import { removeErrors } from '../../actions/errorActions';
import classnames from 'classnames';
import history from '../../history';
import M from 'materialize-css';

class Register extends Component {
	state = {
		name: '',
		email: '',
		password: '',
		password2: '',
		errors: {}
	};

	componentDidMount = () => {
		// If logged in and user navigates to Register page, should redirect them to dashboard
		if (this.props.auth.isAuthenticated) {
			history.push('/dashboard');
        }
    
        this.componentDidUpdate = () => {
			if (this.props.errors.code) {
                M.toast({ html: `${this.props.errors.code}: ${this.props.errors.message}`, classes: 'red darken-2' });
                this.props.removeErrors();
            }
		};
        }
    
    static getDerivedStateFromProps = (nextProps) => {
        if (nextProps.errors) {
            return {errors: nextProps.errors}
        }
        return null;
    }

	onChange = e => {
		this.setState({ [e.target.id]: e.target.value });
	};

	onSubmit = e => {
		e.preventDefault();

		const newUser = {
			name: this.state.name,
			email: this.state.email,
			password: this.state.password,
			password2: this.state.password2
		};

		this.props.registerUser(newUser);
	};

	render = () => {
		const { errors } = this.state;

		return (
			<div className="container">
				<div className="mt3 row">
					<div className="col s8 offset-s2">
						<Link to="/" className="btn-flat waves-effect">
							<i className="material-icons left">keyboard_backspace</i> Back to home
						</Link>
						<div className="col s12" style={{ paddingLeft: '11.250px' }}>
							<h4>
								<b>Register</b> below
							</h4>
							<p className="grey-text text-darken-1">
								Already have an account? <Link to="/login">Log in</Link>
							</p>
						</div>
						<form noValidate onSubmit={this.onSubmit}>
							<div className="input-field col s12">
								<input
									onChange={this.onChange}
									value={this.state.name}
									error={errors.name}
									id="name"
									type="text"
									className={classnames('', { invalid: errors.name })}
								/>
								<label htmlFor="name">Name</label>
								<span className="red-text">{errors.name}</span>
							</div>
							<div className="input-field col s12">
								<input
									onChange={this.onChange}
									value={this.state.email}
									error={errors.email}
									id="email"
									type="email"
									className={classnames('', { invalid: errors.email })}
								/>
								<label htmlFor="email">Email</label>
								<span className="red-text">{errors.email}</span>
							</div>
							<div className="input-field col s12">
								<input
									onChange={this.onChange}
									value={this.state.password}
									error={errors.password}
									id="password"
									type="password"
									className={classnames('', { invalid: errors.password })}
								/>
								<label htmlFor="password">Password</label>
								<span className="red-text">{errors.password}</span>
							</div>
							<div className="input-field col s12">
								<input
									onChange={this.onChange}
									value={this.state.password2}
									error={errors.password2}
									id="password2"
									type="password"
									className={classnames('', { invalid: errors.password2 })}
								/>
								<label htmlFor="password2">Confirm Password</label>
								<span className="red-text">{errors.password2}</span>
							</div>
							<div className="col s12" style={{ paddingLeft: '11.250px' }}>
								<button
									style={{
										width: '150px',
										borderRadius: '3px',
										letterSpacing: '1.5px',
									}}
									type="submit"
									className="mt3 btn btn-large waves-effect waves-light hoverable red darken-1"
								>
									Sign up
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	};
}

Register.propTypes = {
	registerUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(mapStateToProps, { removeErrors, registerUser })(withRouter(Register));
