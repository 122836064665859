import React from 'react';

export default function(props) {
        const {message} = props;
		return (
			<div id="loader">
				<div className="preloader-wrapper active">
					<div className="spinner-layer spinner-only darken-1">
						<div className="circle-clipper left">
							<div className="circle"></div>
						</div>
						<div className="gap-patch">
							<div className="circle"></div>
						</div>
						<div className="circle-clipper right">
							<div className="circle"></div>
						</div>
					</div>
				</div>
				<p className="flow-text grey-text darken-2 ml1">{message ? message : 'One moment please...'}</p>
			</div>
		);	
};